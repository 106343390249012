.FunnelStageCount {
	border-radius: 0.75rem;
	border: solid 1px var(--base-color-lightest);
	padding: 0.25rem 0.5rem;
}

.BorderPurple {
	border-color: var(--color-violet-6);
	color: var(--color-violet-2) !important;
}
